import React, { useMemo } from 'react';
import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import CmsImage from 'src/components/images/CmsImage';
import { CmsImageForUrl } from 'src/components/images/CmsImageForUrl';
import Image from "src/components/images/Image";
import CSSPoster, { CSSPosterMissing, CSSPosterMissingMovieTitle, CSSPosterSizer, CSSPosterWrapper, type SPosterMissingProps } from 'src/components/posterrow/CSSPoster';
import KinoklubbPosterBanner from 'src/kinoklubbposterbanner/KinoklubbPosterBanner';
import { type ImageVersionType, type MovieType, type StreamingContentType, type WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
import { isMovieType, isStreamingContentType, isWatchableContentType } from "src/lib/movieinfo/typeGuards";
import styled, { css } from 'src/lib/styles/css';
import { DocType_Filmomtale, IFilmomtale, isFilmomtale } from 'src/lib/types/Filmomtale';


//#region [Props]
export type PosterProps = {
    className?: string;
    film: IFilmomtale | MovieType | StreamingContentType | WatchableContentType;
    sizes?: string;
    children?: React.ReactNode;
    posterMissingText?: string;
    showMovieTitleIfPosterIsMissing?: boolean;
    hideTitle?: boolean;
    width?: number;
};

//#endregion

//#region [Component]
export default function Poster({ film, className, sizes, posterMissingText = "Plakat mangler", showMovieTitleIfPosterIsMissing = false, hideTitle = false, width, children }: PosterProps) {
    const [_filmUrl, _hasPoster, _isKinoklubb] = useMemo<[string | null, boolean, boolean]>(() => {
        if (!film) {
            return [null, false, false];
        }
        let url = "";
        let hasPoster = false;
        let isKinoklubb = false;
        if (isMovieType(film)) {
            url = `/film/${film.mainVersionId}`
            hasPoster = !!film.sanityImagePosterUrl;
            isKinoklubb = film.isKinoklubb;
        } else if (isStreamingContentType(film)) {
            url = `/streamingguide/${film.isSeries ? "serie" : "film"}/${film.id}`;
            hasPoster = !!film.sanityImagePosterUrl || (film.imagesPoster?.length ?? 0) > 0;
        } else if (isWatchableContentType(film)) {
            url = film.isCinemaRelevant ?
                `/film/${film.movieId}`
                : `/streamingguide/${film.isSeries ? "serie" : "film"}/${film.streamingContentId}`;
            hasPoster = !!film.sanityImagePosterUrl || (film.imagesPosterStreaming?.length ?? 0) > 0;
        } else {
            url = `/film/${film.mainVersionEDI}`
            hasPoster = (film.postersV2?.length ?? 0) > 0 || !!film.mainPosterV2;
            isKinoklubb = film.isKinoklubb;
        }
        if (!url) {
            return [null, false, false];
        }
        return [url, hasPoster, isKinoklubb];
    }, [film])

    if (!_filmUrl) return null;

    const isUnoptimized = !!width ? true : false;

    return <SPoster className={className}>
        <SPosterWrapper $isPosterMissing={!_hasPoster} $showMovieTitle={showMovieTitleIfPosterIsMissing} data-postermissingtext={posterMissingText} data-movietitle={film.title}>
            <SFilmwebLink to={_filmUrl}>
                {_hasPoster && <>
                    {isFilmomtale(film) && <SCMSPosterImage
                        alt={`Plakat for '${film.title}'`}
                        image={film?.mainPosterV2 ?? film.postersV2?.[0]}
                        sizes={sizes}
                        width={width}
                        unoptimized={isUnoptimized}
                        isPosterAspect />}
                    {isMovieType(film) && <SCMSPosterForUrl
                        imageUrl={film.sanityImagePosterUrl}
                        alt={`Plakat for '${film.title}'`}
                        sizes={sizes}
                        width={width}
                        unoptimized={isUnoptimized}
                        isPosterAspect
                        applyAspectRatio
                    />}
                    {isStreamingContentType(film) && _hasPoster && <>
                        {film.sanityImagePosterUrl && <SCMSPosterForUrl
                            imageUrl={film.sanityImagePosterUrl}
                            alt={`Plakat for '${film.title}'`}
                            sizes={sizes}
                            width={width}
                            unoptimized={isUnoptimized}
                            isPosterAspect

                        />}
                        {!film.sanityImagePosterUrl && <SPosterForUrl
                            image={film.imagesPoster as ImageVersionType[]}
                            alt={`Plakat for '${film.title}'`}
                            unoptimized={isUnoptimized}
                            sizes={sizes}
                        />}
                    </>}
                    {isWatchableContentType(film) && _hasPoster && <>
                        {film.sanityImagePosterUrl && <SCMSPosterForUrl
                            imageUrl={film.sanityImagePosterUrl}
                            alt={`Plakat for '${film.title}'`}
                            sizes={sizes}
                            width={width}
                            unoptimized={isUnoptimized}
                            isPosterAspect
                        />}
                        {!film.sanityImagePosterUrl && <SPosterForUrl
                            image={film.imagesPosterStreaming as ImageVersionType[]}
                            alt={`Plakat for '${film.title}'`}
                            unoptimized={isUnoptimized}
                            sizes={sizes}
                        />}
                    </>}
                </>}
                {_isKinoklubb && <KinoklubbPosterBanner />}
            </SFilmwebLink>
        </SPosterWrapper>
        {!hideTitle && <h1><FilmwebLink to={_filmUrl}>{film.title}</FilmwebLink></h1>}
        {children}
    </SPoster>;
}
//#endregion

//#region [Styles]
const SPoster = styled.article`
	${CSSPoster}
`;

const SPosterWrapper = styled.div<SPosterMissingProps>`
	${CSSPosterWrapper}

	${props => props.$isPosterMissing ? CSSPosterMissing : ""}

	${props => props.$isPosterMissing && props.$showMovieTitle ? CSSPosterMissingMovieTitle : ""}
`;

const SFilmwebLink = styled(FilmwebLink)`
	${CSSPosterSizer}
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;

const CSSImageStaticHeightImage = css`
	width: 100%;
	height: auto;
`;

const SCMSPosterImage = styled(CmsImage)`
	${CSSImageStaticHeightImage}

`;

const SCMSPosterForUrl = styled(CmsImageForUrl)`
	${CSSImageStaticHeightImage}
`;

const SPosterForUrl = styled(Image)`
	${CSSImageStaticHeightImage}
`;
//#endregion